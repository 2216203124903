// React
import React from 'react';

// Gatsby
import { graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import { StickyContainer } from 'react-sticky';

// Utilities
import { useTheme } from 'hooks';

// Components
import SEO from 'components/seo';
import ProductHero from 'components/_product/productHero';

const ProductGiftCardComponent = props => {
    const {
        location,
        pageContext,
        pageContext: { locale },
        data: {
            datoCmsGiftCard,
            datoCmsGiftCard: { seoMetaTags, variations },
            datoCmsPageShop,
        },
    } = props;

    // Set theme
    const productTheme = useTheme(s.color('white'));

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                <StickyContainer>
                    <ContentWrapper>
                        <ProductHero
                            {...{
                                parent: datoCmsPageShop,
                                parentTheme: datoCmsPageShop.theme,
                                theme: productTheme,
                                product: datoCmsGiftCard,
                                productVariations: variations,
                                location,
                                pageContext,
                            }}
                        />
                    </ContentWrapper>
                </StickyContainer>
            </s.layout.PageContainer>
        </>
    );
};

export default ProductGiftCardComponent;

export const productGiftCardQuery = graphql`
    query ProductGiftCardQuery($itemId: String!) {
        datoCmsGiftCard(id: { eq: $itemId }) {
            ...ProductGiftCardFragment
        }
        datoCmsPageShop {
            theme {
                ...Theme
            }
            slug
        }
    }
`;

const ContentWrapper = styled.div`
    position: relative;
    z-index: ${s.layout.zIndex.content};
`;
